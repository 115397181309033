window.onscroll = function () {
  var nav = document.getElementById("navigation");
  if (window.scrollY <= 111) {
    nav.classList.remove("scroll-nav");
    let animation = document.getElementById("lottie-logo");
    animation.setDirection(-1);
    animation.play();
  } else {
    nav.classList.add("scroll-nav");
    let animation = document.getElementById("lottie-logo");
    animation.setDirection(1);
    animation.play();
  }
}

window.onresize = function() {
  closeMegaMenu();
}

function toggleMobileNav() {
  $('#navbar, .mobile-mega-menu').toggleClass('open');
  if($('#navbar').hasClass('open')) {
    $('html').css('overflow', 'hidden');
  } else {
    $('html').css('overflow', 'scroll');
  }
}

function openExpertiseMobileMenu() {
  $('.mobile-mega-menu .top-level').addClass('pushed');
  $('.mobile-mega-menu .expertise').addClass('pulled');
}

function closeExpertiseMobileMenu() {
  $('.mobile-mega-menu .top-level').removeClass('pushed');
  $('.mobile-mega-menu .expertise').removeClass('pulled');
}

function openSubExpertiseMobileMenu(subExpertise) {
  $('.mobile-mega-menu .top-level').addClass('pushed');
  $('.mobile-mega-menu .expertise').removeClass('pulled');
  $('.mobile-mega-menu .expertise').addClass('pushed');
  $('.mobile-mega-menu .sub-expertise.' + subExpertise).addClass('pulled');
}

function closeSubExpertiseMobileMenu(subExpertise) {
  $('.mobile-mega-menu .top-level').addClass('pushed');
  $('.mobile-mega-menu .expertise').addClass('pulled');
  $('.mobile-mega-menu .expertise').removeClass('pushed');
  $('.mobile-mega-menu .sub-expertise.' + subExpertise).removeClass('pulled');
}

function openCrossPracticeMobileMenu() {
  $('.mobile-mega-menu .expertise').addClass('pushed');
  $('.mobile-mega-menu .cross-practice').addClass('pulled');
}

function closeCrossPracticeMobileMenu() {
  $('.mobile-mega-menu .expertise').removeClass('pushed');
  $('.mobile-mega-menu .cross-practice').removeClass('pulled');
}

function openAboutMobileMenu() {
  $('.mobile-mega-menu .top-level').addClass('pushed');
  $('.mobile-mega-menu .about').addClass('pulled');
}

function closeAboutMobileMenu() {
  $('.mobile-mega-menu .top-level').removeClass('pushed');
  $('.mobile-mega-menu .about').removeClass('pulled');
}

function openRecruitmentMobileMenu() {
  $('.mobile-mega-menu .top-level').addClass('pushed');
  $('.mobile-mega-menu .recruitment').addClass('pulled');
}

function closeRecruitmentMobileMenu() {
  $('.mobile-mega-menu .top-level').removeClass('pushed');
  $('.mobile-mega-menu .recruitment').removeClass('pulled');
}

function closeMegaMenu() {
  $('#navbar .nav-links .expand').removeClass('open');
  $('#navigation .mega-menu').removeClass('open');
  $('html').css('overflow', 'scroll');
}

function toggleMegaMenu(megaMenu) {
  if ($('#navbar .nav-links .' + megaMenu).hasClass('open')) {
    closeMegaMenu();
    $('html').css('overflow', 'scroll');
  } else {
    closeMegaMenu();
    $('html').css('overflow', 'hidden');
    $('#navbar .nav-links .' + megaMenu).toggleClass('open');
    $('#navigation .mega-menu.' + megaMenu).toggleClass('open');
  }
}

function toggleExpertiseSubMenu(expertise) {
  $('.expertise-menu .expertise-item, .sub-expertise-wrapper .sub-expertise').removeClass('active');
  $('.expertise-menu .expertise-item.' + expertise + ', .sub-expertise-wrapper .sub-expertise.' + expertise).addClass('active');
}

function calculateBarristerExpertiseHeight() {
  $('.content-tabs').css('height', $('.content-tabs .tab.active').height());
}

function controlExpertiseTabs(expertise, specialism) {
  // Control Expertise Nav
  $('.expertise-list .expertise').removeClass('active');
  $('.expertise-list .expertise[expertise="' + expertise + '"]').addClass('active');

  // Control Specialism Nav
  $('.extras-and-subnav .expertise-subnav').removeClass('active');
  $('.extras-and-subnav .expertise-subnav .sub-expertise').removeClass('active');
  $('.extras-and-subnav .expertise-subnav[expertise="' + expertise + '"]').addClass('active');
  $('.extras-and-subnav .expertise-subnav .sub-expertise[expertise="' + expertise + '"][specialism="' + specialism + '"]').addClass('active');

  // Control Quotes
  $('.quotes-wrapper .barrister-quote').removeClass('active');
  $('.quotes-wrapper .barrister-quote[expertise="' + expertise + '"]').addClass('active');

  // Control Content Tabs
  $('.content-tabs .tab').removeClass('active');
  $('.content-tabs .tab[expertise="' + expertise + '"][specialism="' + specialism + '"]').addClass('active');
  calculateBarristerExpertiseHeight();
  window.history.replaceState(null,null,'?expertise=' + expertise + '&specialism=' + specialism);
}

function toggleBarristerAccordian(el) {
  // console.log(el.parentElement);
  el.parentElement.classList.toggle('active');
}

function toggleExtra(type) {
  $('.extras-and-subnav .summary-extras .extra.' + type).toggleClass('open');
}

function toggleShortlist() {
  $('#barrister_shortlist').toggleClass('open');
}

function togglePracticeManager(slug) {
  console.log('Select Practice Manager: ' + slug);
  // Reset current grid
  $('.manager-grid .practice-manager').removeClass('active');
  $('.manager-selection .practice-manager').removeClass('active');

  // Add required class to selected Manager
  $('.manager-grid .' + slug).addClass('active');
  $('.manager-selection .' + slug).addClass('active');
}

function toggleExpertiseFilter() {
  $('.expertise-filter .expertise-button').toggleClass('active');
  $('.expertise-filter .expertise-options').toggleClass('open');
}

function toggleBlogTypeFilter() {
  $('.blog-type-filter .blog-type-button').toggleClass('active');
  $('.blog-type-filter .blog-type-options').toggleClass('open');
}

function calcTabHeight() {
  $('.content-tabs').css('height', $('.content-tabs .tab.active').height());
}

function changeTab(tab) {
  // Control Tab Nav
  $('.tab-wrapper .tab-controls .tab-button').removeClass('active');
  $('.tab-wrapper .tab-controls .tab-button[tab="' + tab + '"]').addClass('active');

  // Control Tabs
  $('.tab-wrapper .content-tabs .tab').removeClass('active');
  $('.tab-wrapper .content-tabs .tab[tab="' + tab + '"]').addClass('active');

  calcTabHeight();
}

function toggleQA(el) {
  let answerHeight = el.parentElement.querySelector('.answer-wrapper .answer-content').offsetHeight;

  if($(el).parent().hasClass("open")) {
    $(el).parent().remove('open');
    el.parentElement.querySelector('.answer-wrapper').style.maxHeight = '0px';
  } else {
    $(el).parent().addClass('open');
    el.parentElement.querySelector('.answer-wrapper').style.maxHeight = (answerHeight + 15) + 'px';
  }
  
  setTimeout(function() {
    calcTabHeight();
  }, 520);
}
